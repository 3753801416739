const galleryImages = [
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZCuLJ5Z9nKi4X4HPgpdV4DcsBudUm4dmymy&linkpassword=undefined&size=1372x1112&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZmjLJ5Zwqpvj18PoMbpiP7KM6QXwzsa3Iby&linkpassword=undefined&size=1372x1028&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZHjLJ5Z0UD6ART0mRfztCx5L8LsxfjkQ1VX&linkpassword=undefined&size=1372x1028&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZJjLJ5Z9iKotRB6y2fgqGuUMeY7EfNY8S9X&linkpassword=undefined&size=1372x1028&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZzjLJ5Z3hBvctswqPYq2GqQ45xwMXsW6sC7&linkpassword=undefined&size=1372x1028&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZ6SLJ5ZxluX8m9Qpqmy0dB2fm8vUJKKTur7&linkpassword=undefined&size=1372x1028&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZfYLJ5ZDcDa59pHMUjl284gtYwuYJhH5RfV&linkpassword=undefined&size=1372x1028&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZVYLJ5ZStviQkAGCtjUBK3Nlszi2L0EiJUX&linkpassword=undefined&size=1372x1028&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZK8LJ5ZM9RUqsEFH9Q4soJ4VtQSpbqVTOgk&linkpassword=undefined&size=1028x1372&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZN8LJ5Z1vCy6lLUqIhkHrnKpTF39jdiy0B7&linkpassword=undefined&size=1372x1028&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZvRcNVZiG9pNMNl9hfpJ2fO5BNdtF58AapX&linkpassword=undefined&size=1365x1025&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZdHcNVZ0AkxbrACB75E9z015uyog8LHMavy&linkpassword=undefined&size=1365x1025&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZm0cNVZzuMzTIlBcdJ6gFIiSF9bLB1Az1iX&linkpassword=undefined&size=1025x1365&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZr9WUVZnkQze0aLcP7GuzghGgcxhzuJHeJ7&linkpassword=undefined&size=1365x1025&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZLeWUVZviUEzvruyPph3iu9K80YkRqUYHX0&linkpassword=undefined&size=768x1024&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZ0N3OVZuKeV8783ynfkulSsl6jIr4eixzrV&linkpassword=undefined&size=1368x1025&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZz12TVZeD8EBBxvjPjBQht5niV4qBUBT1aV&linkpassword=undefined&size=1365x1025&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZL12TVZoMWi6TWkDhSGhKK54nHl4JpoucY7&linkpassword=undefined&size=1365x1025&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZ3jbMVZlpnqPC2k2zzp5gdE32qpQBkR8vJk&linkpassword=undefined&size=1025x1365&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZojbMVZo8OJC9D8SrkOCsv8m19ivhbuGiLX&linkpassword=undefined&size=1025x1365&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZNhbMVZAiNna4yNp6Xo0Nu3QWupT8icCPwV&linkpassword=undefined&size=1025x1365&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZIhbMVZ58I1V5ns7P76K0SuW5qPxuFShcAk&linkpassword=undefined&size=1025x1365&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZ4QbMVZB6gIj01xgpfDKDOIHnvqSRpr7K97&linkpassword=undefined&size=1025x1365&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZJQbMVZtTeSpUv9wcuaJ12eOs7sx0tbI7iV&linkpassword=undefined&size=1365x1025&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZlcWhVZY4610XRQOLHkYvxSb7OjpbdyL9ky&linkpassword=undefined&size=1025x1365&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZ57shVZ7LdTquMmTAhD0fYU9joq7uVWjQAy&linkpassword=undefined&size=971x768&crop=0&type=auto"
    },
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZOObzVZ7CdQiE3TYVBWLfBF5AWQILOYJWJy&linkpassword=undefined&size=1365x1025&crop=0&type=auto"
},
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZ4Op5VZCRe5vsymo7QES1vXaiyiyBtA0zbk&linkpassword=undefined&size=1024x1024&crop=0&type=auto"
},
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZtAp5VZuqGlHCnKDj5lYJlqiYXKCkXy8N5X&linkpassword=undefined&size=1024x1024&crop=0&type=auto"
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZjji6XZag69LWb1KVhxn6Yvcd40D4HDaEdV&linkpassword=undefined&size=1365x1025&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZPfi6XZIVr1QEgML502e8tDQ6t48Ho4oDpk&linkpassword=undefined&size=1365x1032&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZGBi6XZUltqxSTjNpLUEz4zsW2fy47qnYjX&linkpassword=undefined&size=1025x1365&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZAXshVZ4SLQKxkeDSQVyu05wmSbLBUXVy3k&linkpassword=undefined&size=1025x1365&crop=0&type=auto'
},
    {
        src: "https://api.pcloud.com/getpubthumb?code=XZ00shVZoqLj2oqVLomEDd6I3hFfDJdGnb3X&linkpassword=undefined&size=1025x1365&crop=0&type=auto"
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZxWi6XZmnTbX8iqrXYsK3fNW0BeuFSm3aGX&linkpassword=undefined&size=1365x1025&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZEMOMkZWI9FhYTjsek6gRa6TvczCyrnn8Gk&linkpassword=undefined&size=1600x1200&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZNADQXZMCs2OiEU4sztqrAvN8bfDFhG2p5V&linkpassword=undefined&size=1365x1025&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZDGDQXZSevEYtQc1o0LAHWsPrH4XpuOK1QV&linkpassword=undefined&size=1365x1025&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZtrDQXZDtyjx9LDyNh0nont06ENrF9UXKe7&linkpassword=undefined&size=1025x1365&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZwGDQXZ0aXCEpaCt65Hiur0tVvuayJN8HAV&linkpassword=undefined&size=1025x1365&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZEGDQXZ8omv8L217Bk6JatGsDWYBpMH8Oo7&linkpassword=undefined&size=1025x1365&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZ9mBfXZign3wSwSe9pT9djkKOhvVjszPOlV&linkpassword=undefined&size=1365x1025&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZVyw7XZnvAj26ODKtmDIvYQb7xGJLdmyYuX&linkpassword=undefined&size=1365x1025&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZmTOMkZvQXUMO0IqSVl3jc4fwAMTpbBusQ7&linkpassword=undefined&size=1200x1600&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZjzw7XZ29ARxjOKdpYey1hezCKjRHoQqDy7&linkpassword=undefined&size=1365x1025&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZwPOMkZj4F9ItlcwKJuLzvxbJaKGL1xNeyV&linkpassword=undefined&size=1200x1600&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZaIUMkZHwBCzkkusKp0Cnw0Bo07F7QHhOf7&linkpassword=undefined&size=1920x1440&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZBCOMkZcEUWyDbkQ4S4rvF0vXiLqjbd4AbX&linkpassword=undefined&size=1920x1440&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZrCOMkZo0CaBCPfvffWn1jsdUiIP7G50p87&linkpassword=undefined&size=1920x1440&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZBxOMkZOqYeaocGfEFz74a8MruprHx2FUTk&linkpassword=undefined&size=756x1008&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZSUUMkZmiu679cGDrHaFV5YRCoBmLoPcjky&linkpassword=undefined&size=756x1008&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZfUUMkZavrmvu3NFL7V9cts3sNCPuFwzjoy&linkpassword=undefined&size=1008x756&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZnxOMkZiRyOQVFy4o7jrONxok7tSLt5ERBy&linkpassword=undefined&size=1920x1440&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZTOUMkZDhTaV3IdLV84AfeiTjtjHkFmDU97&linkpassword=undefined&size=1920x1676&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZAxOMkZeEDXkbIqM4H70NvYqg8nA42BAoSk&linkpassword=undefined&size=1920x1440&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZ5OUMkZg3MyPo8RB40BYhnn2KRHmF7qlwqV&linkpassword=undefined&size=1920x1440&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZ3WvMkZmtd6BQ2kcpSEG5AGSc1AKbpdDFtk&linkpassword=undefined&size=1920x1440&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZYnvMkZ906EK06KYdShe3f4OYN72FrM0Kz7&linkpassword=undefined&size=756x1008&crop=0&type=auto'
},
    {
        src: 'https://api.pcloud.com/getpubthumb?code=XZNnvMkZxxCkiB3OMoQ51emfTrbSfVXslGWk&linkpassword=undefined&size=756x1008&crop=0&type=auto'
}
]

export default galleryImages;