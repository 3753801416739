import React, {Component} from 'react';
import '../styles/Counters.scss';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export default class Counters extends Component {
    state = {
        didViewCountUp: false
    };
    
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    render() {
        return (
            <div className="counters-container">
                <div>
                    <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                        <CountUp start={0} end={this.state.didViewCountUp ? 453 : 0} duration={2.5} suffix="  " prefix="  "></CountUp>
                    </VisibilitySensor>
                    <h2>Completed projects</h2>
                </div>

                <div>
                    <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                        <CountUp start={0} end={this.state.didViewCountUp ? 12 : 0} duration={2.5} suffix="  " prefix="  "></CountUp>
                    </VisibilitySensor>
                    <h2>Years of installation and renovation experience</h2>
                </div>
                    
                

                <div>
                    <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                        <CountUp start={0} end={this.state.didViewCountUp ? 100 : 0} duration={2.5} suffix=" %" prefix="  "></CountUp>
                    </VisibilitySensor>
                    <h2>Competitive pricing</h2>
                </div>
                    
                <div>
                    <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                        <CountUp start={0} end={this.state.didViewCountUp ? 201 : 0} duration={2.5} suffix="K $" prefix="over  "></CountUp>
                    </VisibilitySensor>
                    <h2>Saved to our clients</h2>
                </div>
            </div>
        )
    }
}